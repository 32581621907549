import React from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

import styles from './style.module.scss'
import noImage from '@assets/images/placeholder/article-placeholder.jpg'
import { BLUR_DATA_URL_BASE64, DOMAIN, S3_PREFIX } from '@shared/constants'

const MyImage = dynamic(() => import('@shared/components/myImage'))

function Stories({ data, className }) {
  return (
    <div className={`${styles.item} d-block small-head overflow-hidden position-relative br-sm a-transition ${className || ''}`}>
      <MyImage
        blurDataURL={BLUR_DATA_URL_BASE64}
        placeholder="blur"
        src={data?.oCoverImg?.sUrl ? `${S3_PREFIX}${data?.oCoverImg?.sUrl}` : noImage}
        // src={data?.oCoverImg?.sUrl?.includes('http') ? data?.oCoverImg?.sUrl : noImage}
        alt={data?.oCoverImg?.sText || data?.sName || data?.sTitle}
        fill
        sizes="(max-width: 768px) 80px, 320px"
        // height="432"
        // width="260"
        // className="h-100"
        // layout="fill"
      />
      <h4 className="small-head mb-0 position-relative h-100 font-medium">
        {/* <CustomLink href={data?.oSeo?.sSlug || ''}> */}
        <a
          href={DOMAIN + data?.oSeo?.sSlug + '/'}
          target="_blank"
          className="small-head mb-0 position-absolute p-2 p-md-3 pb-4 pb-md-4 bottom-0 start-0 h-100 d-flex align-items-end w-100"
          rel="noreferrer"
          data-enable-stories-player="true"
        >
          <span>{data?.sTitle}</span>
        </a>
        {/* </CustomLink> */}
      </h4>
      <div className={`${styles.storyCount} d-flex position-absolute bottom-0 mb-2 w-100 ps-1 pe-2 opacity-75`}>
        {[...Array(data?.nPage || 0)].map((_, i) => (
          <div key={`number${i}`} className={`${styles.count} flex-grow-1 ms-1 rounded-pill`}></div>
        ))}
      </div>
    </div>
  )
}

Stories.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string
}

export default Stories
